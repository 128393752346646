import { AnyAction, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { ROJ } from 'gerdoo-api';

export type IControllerActionPanel = ROJ.IControllerActionPanel & {locked: boolean};

const actionPanelSlice = createSlice({
  name: 'actionPanel',
  initialState: {
    locked: false,
    info: [],
    input: {},
  },
  reducers: {
    update: (state, action: PayloadAction<ROJ.IControllerActionPanel>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    lock: (state) => {
      return {
        ...state,
        locked: true,
      }
    },
    unlock: (state) => {
      return {
        ...state,
        locked: false,
      }
    },
  },
});

export const actionPanelReducer: Reducer<IControllerActionPanel, AnyAction> = actionPanelSlice.reducer;
export const actionPanelActions = { ...actionPanelSlice.actions };
