import { useState } from 'react';
import './JoinForm2.scss';

export const JoinForm: React.FC<{join: (name: string, code: string) => void}> = ({join}) => {
    const [name, setName] = useState('');
    const [code, setCode] = useState('');

    const disabled = name.length < 2 || code.length < 4;

    return <div className="joinForm container-login100">
        <div className="wrap-login100">
            <div className="login100-form validate-form">
                <span className="login100-form-title p-b-48">
                    <i className="zmdi zmdi-font"></i>
                </span>
                <div className="wrap-input100 validate-input">
                    <input className={`input100 ${name ? 'has-val' : ''}`} type="text" name="name" value={name} onChange={(evt) => setName(evt.target?.value?.slice(0, 8))} />
                        <span className="focus-input100" data-placeholder="Name"></span>
                </div>
                <div className="wrap-input100 validate-input" data-validate="Enter password">
                    <span className="btn-show-pass">
                        <i className="zmdi zmdi-eye"></i>
                    </span>
                    <input className={`input100 ${code ? 'has-val' : ''}`} type="text" name="roomCode" 
                        value={code}
                        maxLength={4}
                        onChange={(evt) => setCode((evt.target?.value || '').toLocaleUpperCase())}
                    />
                        <span className="focus-input100" data-placeholder="Room Code"></span>
                </div>
                <div className="container-login100-form-btn">
                    <div className={`wrap-login100-form-btn ${disabled ? 'disabled' : ''}`}>
                        <div className={`login100-form-bgbtn ${disabled ? 'disabled' : ''}`}></div>
                        <button className="login100-form-btn" disabled={disabled} onClick={() => join(name, code)}>
                            { 'Join' }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
