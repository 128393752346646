import _ from 'lodash';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { IonCard, IonCol, IonGrid, IonIcon, IonLoading, IonPage, IonRow, IonText } from '@ionic/react';
import { tvOutline } from 'ionicons/icons';

import { APInput, ROJ } from 'gerdoo-api';
import { IControllerState } from 'gerdoo-controller-connector';

import { AppContext } from '../apps/ControllerApp';
import { CheckBoxInput, PickerInput, RadioInput, TapInput } from './inputs';
import { RojCard, RojImage } from './RojAvatar';

export const ActionPanel: React.FC = () => {
    const { connector } = useContext(AppContext);
    const { actionPanel, gameData: game } = useSelector((state: IControllerState) => state);
    const { players } = game;
    const { info, input } = actionPanel;

    return <>
        <>
            {/* {info.map((row, i) => <IonRow key={i}>
                {row.type === 'text' && <IonCol className='ion-text-center ion-padding-bottom'>
                    <IonText>
                        {row.data.size === 'lg' && <h2>{row.data.text}</h2>}
                        {row.data.size === 'md' && <h5>{row.data.text}</h5>}
                        {row.data.size === 'sm' && <h6 style={{ fontSize: 12 }}>{row.data.text}</h6>}
                    </IonText>
                </IonCol>}
                {row.type === 'image' && <IonCol className='ion-text-center'>
                    <IonIcon style={{ fontSize: 200 }} icon={tvOutline} />
                </IonCol>}
                {row.type === 'list' && row.data.collectionId === 'players' && <PlayerListView row={row} players={players as ROJ.GameEntityCollection<'player'>} />}
            </IonRow>)} */}

            {_.keys(input).map((inputKey) => <div key={inputKey}>
                <div style={{width: '100%'}}>
                    {getInputComponent(inputKey, input[inputKey])}
                </div>
                <div style={{width: '100%'}}>
                    { (input[inputKey]?.hints || []).map(hint => <div className='ion-padding ion-text-center'>
                        <IonText color='warning'>{hint}</IonText>
                    </div>) }
                </div>
            </div>)}
        </>
    </>;

    function getInputComponent(inputKey: string, input: APInput): JSX.Element {
        if (inputKey === 'concede') return <></>;

        switch (input.type) {
            case 'picker': return <PickerInput
                input={input}
                game={game}
                onAction={(action, data) => connector.sendPlayerInput(inputKey, action, data)}
            />;

            case 'checkbox': return <CheckBoxInput
                input={input}
                game={game}
                onAction={(action, data) => connector.sendPlayerInput(inputKey, action, data)}
            />;

            case 'radio': return <RadioInput
                input={input}
                game={game}
                onAction={(action, data) => connector.sendPlayerInput(inputKey, action, data)}
            />;

            case 'tap': return <TapInput
                input={input}
                game={game}
                onAction={(action, data) => connector.sendPlayerInput(inputKey, action, data)}
            />;

            default:
                return <></>;
        }
    }
}

const PlayerListView: React.FC<{ row: ROJ.APInfoRowOf<'list'>, players: ROJ.GameEntityCollection<'player'> }> = (props) => {
    const { row, players: allPlayers } = props;
    const players = _.values(allPlayers).filter(it => row.data.items.indexOf(it.id) >= 0);

    return <IonCol>
        <IonRow className='ion-text-center ion-justify-content-center ion-padding-bottom'>
            {players.map(player => {
                return <RojCard caption={player.name} img={<RojImage type='character' id={player.character} />} />;
            })}
        </IonRow>
    </IonCol>;
}
