import { ROJ } from "gerdoo-api";
import { IMissionReportState, IPhaseState, IPickingState } from "../state";
// TODO: move these imports to roj-api

// async function getLeakersList(state: IPhaseState): Promise<string> {
//     const players = await state.getPlayers();
//     const leakers = await players.filter(p => p.team === 'leakers');
//     const tags = leakers.map(leaker => leaker.role === 'mole' ? '[role.mole]' : `[player.${leaker.pid}]`);
//     return tags.join(' ');
// }

// async function getTrusteeSuspects(state: IPhaseState): Promise<string> {
//     const players = await state.getPlayers();
//     const suspects = players.filter(p => p.role === 'agent' || p.role === 'faker');
//     const tags = suspects.map(sus => `[player.${sus.pid}]`);
//     return tags.join(' ');
// }

// async function getLastTaskforce(state: IMissionReportState): Promise<string> {
//     const squad = await state.getSquad();
//     const tags = squad.map(pid => `[player.${pid}]`);
//     return tags.join(' ');
// }

// async function getPowerplaySecret(state: IPickingState, player: ROJ.IPlayer): Promise<string> {
//     const powerType = (await state.getLastPowerplay()).type;
//     const actorId = (await state.getLastPowerplay()).actorId;
//     const targetId = (await state.getLastPowerplay()).targetId;
//     const res = (await state.getLastPowerplay()).result;
//     const notif = (await state.getLastPowerplay()).notify;

//     if (player.pid === actorId) {
//         if (res.length > 0) return `${res}`;
//     }
//     else if (player.pid === targetId && notif.length > 0) {
//         return `${notif}`;
//     }
//     else{
//         return `${powerType.charAt(0).toUpperCase() + powerType.slice(1)} was given to a random Loyalist.`
//     }
// }

export function extractTerms(msg: string, game: ROJ.IControllerGameData): ROJ.Term[] {
    const terms: ROJ.Term[] = [];

    const tokens = (msg || '').split(/[\s,]+/);
    for (const token of tokens) {
        if (token.startsWith('[') && token.endsWith(']')) {
            const refTag = token.slice(1, -1);
            const [entity, refId, property] = refTag.split('.');
            terms.push({
                type: entity as any,
                id: refId,
                property,
            });
        } else {
            if (terms.length > 0 && terms[terms.length - 1].type === 'text') {
                terms[terms.length - 1].text += ` ${token}`;
            } else {
                terms.push({
                    type: 'text',
                    text: token,
                });
            }
        }
    }

    return terms;
}

export function getSecretValue(ref: string, inbox: ROJ.IControllerInbox): string {
    let secret: ROJ.IGameSecret | undefined;
    if (ref && ref.startsWith('secret')) {
        const secretId = ref.split('.')[1];
        if (secretId) {
            secret = inbox.secrets[secretId];
        }
    } else {
        return ref || '';
    }

    if (!secret) return '';

    switch (secret.type) {
        case 'powerplay_actor': return secret.data.pid;
        case 'powerplay_target': return secret.data.pid || secret.data.team;
        case 'powerplay_result': return secret.data.result;
        case 'player_team': return secret.data.team;
        case 'player_role': return secret.data.role;
    }

    return '';
}

export function reinstatePowerplay(ppid: string, gameData: ROJ.IControllerGameData, inbox: ROJ.IControllerInbox): ROJ.IPowerPlay | undefined {
    const powerplay = gameData.powerplays[ppid];
    if (powerplay) {
        const actorId = getSecretValue(powerplay.actorId, inbox);
        const targetId = getSecretValue(powerplay.targetId!, inbox);
        const result = getSecretValue(powerplay.result!, inbox);

        return {
            ...powerplay,
            actorId,
            targetId,
            result,
        };
    }
}

export function reinstatePlayer(pid: string, gameData: ROJ.IControllerGameData, inbox: ROJ.IControllerInbox): ROJ.IPlayer | undefined {
    const player = gameData.players[pid];
    if (player) {
        const team = getSecretValue(player.team, inbox) as ROJ.Team;
        const role = getSecretValue(player.role, inbox) as ROJ.Role;

        return {
            ...player,
            pid: player.id,
            uuid: '[inaccessible]',
            role,
            team,
        };
    }
}

// function mergeSecretsIntoGameData(gameData: ROJ.IControllerGameData, inbox: ROJ.IControllerInbox): ROJ.IControllerGameData {
//     for (const secretId in inbox.secrets) {
//         const secret = inbox.secrets[secretId];
//         switch (secret.type) {
//             case 'powerplay_actor': gameData.powerplays[secret.data.powerplayId].actorRef = secret.data; break;
//             case 'powerplay_target': gameData.powerplays[secret.data.powerplayId].targetRef = secret.data; break;
//             case 'powerplay_result': gameData.powerplays[secret.data.powerplayId].result = secret.data.result; break;
//             case ''
//         }
//     }
// }