import { Reducer, AnyAction } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GerdooAuthToken } from 'gerdoo-api';

import { authenticate } from "../thunks";

export interface IConnectionState {
  state: 'auth-pending' | 'auth-success' | 'auth-fail' | 'connecting' | 'connected' | 'joining' | 'joined' | 'timeout' | 'disconnected';
  authToken?: GerdooAuthToken;
  roomCode?: string;
}

const initialState: IConnectionState = {
  state: 'auth-pending',
  authToken: undefined,
  roomCode: '',
};

const connectionSlice = createSlice({
  name: 'connection',
  initialState: initialState,
  reducers: {
    // Client-side
    connecting: (state) => {
      return {
        ...state,
        state: 'connecting'
      }
    },
    joining: (state) => {
      return {
        ...state,
        state: 'joining',
      }
    },
    timeout: (state) => {
      return {
        ...state,
        state: 'timeout',
      }
    },
    disconnected: (state) => {
      return {
        ...state,
        state: 'disconnected',
      }
    },

    // Server Updates
    authSuccess: (state, action: PayloadAction<GerdooAuthToken>) => {
      if (state.state !== 'auth-pending') return;
      return {
        ...state,
        authToken: action.payload,
        state: 'auth-success',
      }
    },
    authFail: (state) => {
      if (state.state !== 'auth-pending') return;
      return {
        ...state,
        state: 'auth-fail',
      }
    },
    connected: (state) => {
      if (state.state !== 'connecting') return;
      return {
        ...state,
        state: 'connected',
      }
    },
    joinFailed: (state) => {
      if (state.state !== 'joining') return;
      return {
        ...state,
        state: 'connected',
      }
    },
    joined: (state, action: PayloadAction<{roomCode: string}>) => {
      return {
        ...state,
        roomCode: action.payload.roomCode,
        state: 'joined',
      }
    },
    left: (state) => {
      return {
        ...state,
        roomCode: undefined,
        state: 'connected',
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(authenticate.pending, (state) => {
      return {
        ...state,
        state: 'auth-pending',
      }
    });
    builder.addCase(authenticate.fulfilled, (state) => {
      return {
        ...state,
        state: 'auth-success',
      }
    });
    builder.addCase(authenticate.rejected, (state) => {
      return {
        ...state,
        state: 'auth-fail',
      }
    });
  }
});

export const connectionReducer: Reducer<IConnectionState, AnyAction> = connectionSlice.reducer;
export const connectionActions = {
  ...connectionSlice.actions,
  authenticate,
};
