import { IGameStateChunk } from "./GameState";

export class NumberState implements IGameStateChunk<number> {
    private _value: number;

    constructor(private readonly _key: string) {}

    async init(value: number): Promise<void> {
        this._value = value;
    }

    async load(value: number): Promise<NumberState> {
        this._value = value;
        return this;
    }

    async clear(): Promise<void> {
        this._value = 0;
    }

    async key(): Promise<string> {
        return this._key;
    }

    async value(): Promise<number> {
        return this._value;
    }

    async dec(): Promise<number> {
        this._value--;
        return this._value;
    }
}
