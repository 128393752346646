import { Reducer, AnyAction } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GerdooAuthToken, ROJ } from 'gerdoo-api';

const meSlice = createSlice({
  name: 'me',
  initialState: {
  } as ROJ.IPlayer,
  reducers: {
    update: (state, action: PayloadAction<ROJ.IPlayer>) => {
      return {
        ...state,
        ...action.payload,
      };
    }
  },
});

export const meReducer = meSlice.reducer;
export const meActions = { ...meSlice.actions };
