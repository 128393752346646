import * as _ from 'lodash';
import { ROJ } from 'gerdoo-api';

export function assert(obj: any, msg: string): void {
  if (!obj) console.error('Assert failed: ' + msg);
}

const goodRoles: ROJ.Role[] = ['staffer', 'agent', 'trustee'];
const badRoles: ROJ.Role[] = ['informant', 'faker', 'testifier', 'mole'];

export function getTeamByRole(role: ROJ.Role): ROJ.Team | undefined {
  if (goodRoles.indexOf(role) >= 0) return 'loyals';
  if (badRoles.indexOf(role) >= 0) return 'leakers';
  
  return undefined;
}

export function getRoundConfig(playerCount: number): ROJ.IRoundConfig[] {
    const roundConfigs = {
        '5': [
            { squadSize: 2, secrecy: 1 },
            { squadSize: 3, secrecy: 1 },
            { squadSize: 2, secrecy: 1 },
            { squadSize: 3, secrecy: 1 },
            { squadSize: 3, secrecy: 1 },
        ],
        '6': [
            { squadSize: 2, secrecy: 1 },
            { squadSize: 3, secrecy: 1 },
            { squadSize: 4, secrecy: 1 },
            { squadSize: 3, secrecy: 1 },
            { squadSize: 4, secrecy: 1 },
        ],
        '7': [
            { squadSize: 2, secrecy: 1 },
            { squadSize: 3, secrecy: 1 },
            { squadSize: 3, secrecy: 1 },
            { squadSize: 4, secrecy: 2 },
            { squadSize: 4, secrecy: 1 },
        ],
        '8': [
            { squadSize: 3, secrecy: 1 },
            { squadSize: 4, secrecy: 1 },
            { squadSize: 4, secrecy: 1 },
            { squadSize: 5, secrecy: 2 },
            { squadSize: 5, secrecy: 1 },
        ],
        '9': [
            { squadSize: 3, secrecy: 1 },
            { squadSize: 4, secrecy: 1 },
            { squadSize: 4, secrecy: 1 },
            { squadSize: 6, secrecy: 2 },
            { squadSize: 5, secrecy: 1 },
        ],
        '10': [
            { squadSize: 3, secrecy: 1 },
            { squadSize: 4, secrecy: 1 },
            { squadSize: 4, secrecy: 1 },
            { squadSize: 6, secrecy: 2 },
            { squadSize: 5, secrecy: 1 },
        ]
    };

    if (playerCount < 5 || playerCount > 10) {
        console.error(`Invalid Player Count: ${playerCount}`);
        return [];
    }

    return roundConfigs[`${playerCount}`];
}

export function getDefaultConfig(playerCount: number): ROJ.IGameDefaultConfig {
    const defaultRoleConfigs: _.Dictionary<ROJ.IGameDefaultConfig> = {
        '5': {
            leakerCount: 2,
            roles: ['agent', 'testifier'],
        },
        '6': {
            leakerCount: 2,
            roles: ['agent', 'testifier'],
        },
        '7': {
            leakerCount: 3,
            roles: ['agent', 'testifier', 'trustee', 'faker'],
        },
        '8': {
            leakerCount: 3,
            roles: ['agent', 'testifier', 'trustee', 'faker'],
        },
        '9': {
            leakerCount: 3,
            roles: ['agent', 'testifier', 'trustee', 'faker', 'mole'],
        },
        '10': {
            leakerCount: 4,
            roles: ['agent', 'testifier', 'trustee', 'faker', 'mole'],
        },
    };

    if (playerCount < 5 || playerCount > 10) {
        console.error(`Invalid Player Count: ${playerCount}`);
        return {};
    }

    return defaultRoleConfigs[`${playerCount}`];
}

export function getAllCharacters() {
    // return ['tiger', 'rhino', 'rabbit', 'owl', 'giraffe', 'hippo', 'gorilla', 'moose', 'crocodile', 'bear', 'bat', 'human1', 'human2'];
    return ['bat', 'bear', 'crocodile', 'gazelle', 'gorilla', 'hippo', 'moose', 'owl', 'pig', 'rabbit', 'rhino', 'tiger', 'wolf'];
}

export function createRoleList(config: ROJ.IGameConfig, playerCount: number): ROJ.Role[] {
    const defaultConfig = getDefaultConfig(playerCount);
    if (!config || !defaultConfig) return [];
  
    let roles: ROJ.Role[] = [];
    if (config?.roles) roles.push(...config.roles);
    roles = _.uniq(roles);

    // no faker w/o trustee
    if (roles.indexOf('faker') >= 0 && roles.indexOf('trustee') < 0) {
        roles = roles.filter(r => r !== 'faker');
    }

    // no testifier w/o agent
    if (roles.indexOf('testifier') >= 0 && roles.indexOf('agent') < 0) {
        roles = roles.filter(r => r !== 'testifier');
    }

    // add testifier if agent
    if (roles.indexOf('testifier') < 0  && roles.indexOf('agent') >= 0) {
        roles.push('testifier');
    }

    let existingLeakers: ROJ.Role[] = roles.filter(r => getTeamByRole(r) === 'leakers');

    // trim leaker roles if too many
    for (const trimmedRole of ['informant', 'faker', 'mole']) {
        existingLeakers = roles.filter(r => getTeamByRole(r) === 'leakers');
        if (existingLeakers.length > defaultConfig.leakerCount) {
            roles = roles.filter(r => r !== trimmedRole);
        } else {
            break;
        }
    }

    // fill up vacant leaker positions with informants
    for (let i = existingLeakers.length; i < defaultConfig.leakerCount; i++) {
        roles.push('informant');
    }
    
    // fill up other vacant positions with staffers
    for (let i = roles.length; i < playerCount; i++) {
        roles.push('staffer');
    }
  
    // cut back the role list to match player count with a warning
    if (roles.length > playerCount) {
        roles = roles.slice(0, playerCount);
        console.warn('Inconsistent game config. LeakerCount was probably too large. Role list was truncated to match the player count.');
    }
  
    return roles;
}
