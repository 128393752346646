import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { IonRadio, IonCol, IonRadioGroup, IonItem, IonLabel, IonChip, IonText, IonAvatar } from "@ionic/react";

import { APRadioInputDataType, APInputDataType, APInputOf, ROJ } from "gerdoo-api";
import { AppContext } from "../../apps/ControllerApp";

function useControlledValue<T>(value: T, submitChange: (v: T) => void): [T, (nv: T) => void] {
    const [changing, setChanging] = useState(false);
    const [changingTo, setChangingTo] = useState<T>(value);

    useEffect(() => {
        if (value === changingTo) {
            setChanging(false);
        }
    }, [value]);

    const setter: (nv: T) => void = (newValue: T) => {
        if (newValue && newValue !== value) {
            setChanging(true);
            setChangingTo(newValue);
            submitChange(newValue);
        }
    }

    return [changing ? changingTo : value, setter];
}

export interface IRadioInputProps {
    game: ROJ.IControllerGameData;
    input: APInputOf<'radio'>;
    onAction: <ACTION extends keyof APRadioInputDataType>(action: ACTION, data: APInputDataType[ACTION]) => void;
}

export const RadioInput2: React.FC<IRadioInputProps> = ({ game, input, onAction }) => {
    const { args, value } = input;
    const { options } = args;
    const [pickedValue, setPickedValue] = useControlledValue(value, (v) => onAction('radio.pick', { id: v }));

    const optionElems = options.map(option =>
        <IonItem key={option.id}>
            <IonLabel>{option.label || `[${option.id}]`}</IonLabel>
            <IonRadio slot="start" value={option.id} />
        </IonItem>
    );

    return <IonCol className='ion-text-center ion-padding-top'>
        <IonRadioGroup value={pickedValue} onIonChange={(evt) => {
            if (value !== evt.detail.value) {
                setPickedValue(evt.detail.value);
            }
        }
        }>{optionElems}</IonRadioGroup>
    </IonCol>
};

export const RadioInput: React.FC<IRadioInputProps> = ({ game, input, onAction }) => {
    const { cdn } = useContext(AppContext);
    const { args, value } = input;
    const { options } = args;
    // const [pickedValue, setPickedValue] = useControlledValue(value, (v) => onAction('radio.pick', { id: v }));

    const optionElems = [];
    for (const entry of options) {
        let optionElem;
        const varStyle = {
            opacity: value === entry.id ? 1.0 : 0.5,
            backgroundColor: value === entry.id ? 'green' : 'black',
            border: value === entry.id ? 'thick solid darkgreen' : 'thick solid grey',
        };

        switch (entry.id) {
            case 'upvote': optionElem = <IonAvatar style={varStyle}><img src={`${cdn}/game-icons/upvote.png`} /></IonAvatar>; break;
            case 'downvote': optionElem = <IonAvatar style={varStyle}><img src={`${cdn}/game-icons/downvote.png`} /></IonAvatar>; break;
            default:
                optionElem = <IonChip className="ion-padding" key={entry.id} color={value === entry.id ? 'success' : ''}>
                    <IonText style={{fontSize: 20}}>{entry.label || `[${entry.id}]`}</IonText>
                </IonChip>;
        }

        optionElems.push(
            <div key={entry.id}
                style={{textDecoration: entry.disabled ? 'line-through' : ''}}
                onClick={() => onAction('radio.pick', { id: entry.id })} className="ion-margin"
            >{optionElem}</div>
        );
    }

    return <IonCol className='ion-text-center ion-padding-top' style={{display: 'flex', justifyContent: 'center'}}>
        {optionElems}
    </IonCol>
};
