import { ROJ } from "gerdoo-api";

export function getPowerplayVerb(power: ROJ.PowerType): string {
    switch (power) {
        case 'wiretap': return 'wiretaps';
        case 'confide': return 'confides in';
    }

    return 'UnknownRogueActionCaption';
}

export function getEffectStatements(me: ROJ.IPlayer, powerplay: ROJ.IPowerPlay): string[] {
    const statements: string[] = [];

    switch (powerplay.type) {
        case 'wiretap':
            statements.push(`[powerplay.${powerplay.id}.actorId] finds out: [powerplay.${powerplay.id}.result]`);
            // const targetNotif = me.team === 'loyals' ? `A Leaker target know who wiretapped them. All Leakers know if a Leaker was wiretapped No names mentioned.` : '';
            // if (targetNotif) statements.push(targetNotif);
            break;

        case 'confide':
            statements.push(`[powerplay.${powerplay.id}.actorId] reveals their allegiance to [powerplay.${powerplay.id}.targetId] .`);
            break;
    }

    return statements;
}

export function getPowerplayDescription(power: ROJ.PowerType): string {
    switch (power) {
        case 'confide': return 'reveal their team to someone.';
        case 'wiretap': return 'see if someone has ever leaked.';
        case 'sniff': return 'see if someone is the Agent.';
        case 'ban': return 'ban someone from task forces in this round.';
        case 'coerce': return 'force someone to vote like you in this round.';
        case 'pry': return 'see if two players are on the same team.';
    }

    return '';
}

export function getRolePreposition(role: ROJ.Role): string {
    if (role === 'staffer') return 'a';
    else if (role === 'informant') return 'an';
    else return 'the';
}

export function getRoleDisplayName(role?: ROJ.Role): string {
    switch (role) {
        case 'agent': return 'Agent';
        case 'faker': return 'Faker';
        case 'informant': return 'Informant';
        case 'mole': return 'Mole';
        case 'staffer': return 'Staffer';
        case 'testifier': return 'Testifier';
        case 'trustee': return 'Trustee';
    }

    return '';
}

export function getRoleDescription(role: ROJ.Role) {
    switch (role) {
        case 'agent': return 'You know who the Leakers are, but you should protect your identity from them.';
        case 'testifier': return 'Identify the [role.agent] to win the game for Leakers.';
        case 'staffer': return 'You start with no information. Rely on your perception and Rogue Actions to gain information.';
        case 'informant': return 'You know who other Leakers are, and you can Leak. (duh!)';
        case 'faker': return 'You appear on the [role.trustee]\'s secret file as a suspect for being the [role.agent] . You may use this as an opening to earn their trust.';
        case 'trustee': return 'You have two suspects that could be [role.agent] . One is the real [role.agent], the other one is the [role.faker]';
        case 'mole': return 'In [role.agent]\'s secret file, your name is redacted from the List of Leakers';
    }
}

export function getTeamDisplayName(team?: ROJ.Team, singular?: boolean): string {
    switch (team) {
        case 'leakers': return singular ? 'Leaker' : 'Leakers';
        case 'loyals': return singular ? 'Loyalist' : 'Loyalists';
    }

    return '';
}

export function getTeamByRole(role: ROJ.Role): ROJ.Team | undefined {
    const loyalists: ROJ.Role[] = ['staffer', 'agent', 'trustee'];
    const leakers: ROJ.Role[] = ['informant', 'faker', 'testifier', 'mole'];
    if (loyalists.indexOf(role) >= 0) return 'loyals';
    if (leakers.indexOf(role) >= 0) return 'leakers';

    return undefined;
}

export function getTeamDescription(team: ROJ.Team) {
    switch (team) {
        case 'leakers': return 'Leakers win if they sabotage 3 missions by leaking, or identify who the [role.agent] is.'
        case 'loyals': return 'Loyalists win if they complete 3 cover-up missions, and protect the identity of the [role.agent]';
    }
}

export function getInputDescription(phase: ROJ.PhaseType): string {
    switch (phase) {
        case 'setup': return 'Choose your character';
        case 'picking': return 'Propose a taskforce';
        case 'voting': return 'Vote for the proposed taskforce';
        case 'mission': return 'Cover-up or Leak?';
        case 'powerplay': return 'Take/Ack rogue action';
        case 'trial': return 'Pick the Agent';
    }

    return '';
}