import _ from 'lodash';
import { APInputActionsMap, APInputArgsMap, APInputDataType, APInputType, APInputValueMap, ROJ } from 'gerdoo-api';
import { createRoleList } from 'gerdoo-util';

import { IBasePhaseState } from './PhaseState.api';

import {
    PlayerState,
    ListState,
    RoundState,
    ProposalState,
    PowerPlayState,
    TrialState,
    NumberState,
} from '.';


export declare type ActionHandler = (state: IBasePhaseState<ROJ.PhaseType>, player: ROJ.IPlayer, action: ROJ.IAction) => Promise<void>;
export declare type ActionValidator = (state: IBasePhaseState<ROJ.PhaseType>, player: ROJ.IPlayer, action: ROJ.IAction) => Promise<boolean>;

export interface IGameStateChunk<T> {
    // new (data: I): IGameStateChunk;
    key: () => Promise<string>;
    value: () => Promise<T>;
    init: (args: any) => Promise<void>;
    load: (value: T) => Promise<IGameStateChunk<T>>;
    clear: (args: any) => Promise<void>;
}

export interface IPhaseInput<PHASE extends ROJ.PhaseType, STATE extends IBasePhaseState<PHASE>, INPUT extends APInputType> {
    type: INPUT;
    isFor: (state: STATE, player: ROJ.IPlayer) => Promise<boolean>;
    getArgs: (state: STATE, player: ROJ.IPlayer) => Promise<APInputArgsMap[INPUT]>;
    getValue: (state: STATE, player: ROJ.IPlayer) => Promise<APInputValueMap[INPUT]>;
    getHints?: (state: STATE, player: ROJ.IPlayer) => Promise<string[]>;
    actions: Map<APInputActionsMap[INPUT], IPhaseAction<STATE, INPUT, APInputActionsMap[INPUT]>>;
}

export type IPhaseAction<
    STATE extends IBasePhaseState<ROJ.PhaseType>,
    INPUT extends APInputType,
    ACTION extends APInputActionsMap[INPUT],
> = {
    validate: (state: STATE, player: ROJ.IPlayer, args: APInputDataType[ACTION]) => Promise<boolean>;
    handle: (state: STATE, player: ROJ.IPlayer, args: APInputDataType[ACTION]) => Promise<void>;
}

export interface IPhase<PHASE extends ROJ.PhaseType, PhaseState extends IBasePhaseState<PHASE>> {
    type: ROJ.PhaseType;
    canGoTo: ROJ.PhaseType[];
    countdown?: number;
    isFinished: boolean;
    inputs: {[id: string]: IPhaseInput<PHASE, PhaseState, APInputType>};
    start: (state: PhaseState, autoFinish?: boolean) => Promise<boolean>;
    resolve: (state: PhaseState, timeup?: boolean) => Promise<boolean>;
    cleanup: (state: PhaseState) => Promise<void>; // Remember, cleanup is important :)
    getPlayerStatus: (pid: string, state: GameState) => Promise<string[]>;
    getEvents?: () => Promise<string[]>;
}

export interface ISerializedGameState {
    players: ROJ.IPlayer[];
    rounds: ROJ.IRound[];
    currentPlays: {[pid: string]: boolean};
    currentPhase: ROJ.PhaseType;
    roleList: string[];
    leaderList: string[];
    usedCharacters: string[];
    usedDoubleVotes: string[];
    proposals: ROJ.IProposal[];
    powerplays: ROJ.IPowerPlay[];
    budget: number;
    trial: ROJ.ITrial;
    ready: string[];
    conceded: string[];
    gameWinner: string;
    inputAcked: boolean;
}

export class GameState {
    config: ROJ.IGameConfig;

    players: {[uuid: string]: PlayerState} = {};
    player_pid_uuid: {[pid: string]: string} = {};
    rounds: RoundState[] = [];
    currentPlays: {[pid: string]: boolean} = {};
    currentPhase: ROJ.PhaseType = 'setup';
    budget: NumberState = new NumberState('patience');
    roleList: ListState<ROJ.Role> = new ListState('roleList');
    leaderList: ListState<string> = new ListState('leaderList');
    usedCharacters: ListState<string> = new ListState('usedCharacters');
    usedDoubleVotes: ListState<string> = new ListState('doubleVotes');
    ready: ListState<string> = new ListState('ready');
    conceded: ListState<string> = new ListState('conceded');
    proposals: ProposalState[] = [];
    powerplays: {[key:string]: PowerPlayState} = {};
    trial: TrialState | undefined; // constructed when needed
    gameWinner: ROJ.Team;
    proceedPhase: ROJ.PhaseType;
    inputReadyPhase: ROJ.PhaseType;
    autoFinish: boolean;

    private _isInitialized = false;

    get isInitialized() { return this._isInitialized; }

    async serialize(): Promise<ISerializedGameState> {
        return ({
            players: _.values(await this.mapPlayers(p => p.value())),
            rounds: await Promise.all(this.rounds.map(r => r.value())),
            currentPlays: await this.getCurrentRound()?.getPlays(),
            currentPhase: this.currentPhase,
            roleList: await this.roleList.getAll(),
            leaderList: await this.leaderList.getAll(),
            usedCharacters: await this.usedCharacters.getAll(),
            usedDoubleVotes: await this.usedDoubleVotes.getAll(),
            proposals: await Promise.all(this.proposals.map(p => p.value())),
            powerplays: _.values(await this.mapPowerplays(p => p.value())),
            ready: await this.ready.getAll(),
            conceded: await this.conceded.getAll(),
            budget: await this.budget.value(),
            trial: await this.trial?.value(),
            gameWinner: this.gameWinner,
            inputAcked: await this.isInputReady(),
        });
    }

    async init(config: ROJ.IGameConfig, data: ROJ.IGameInitialData): Promise<void> {
        await Promise.all([
            ...data.players.map((pdata, i) => {
                pdata.pid = `p${i}`;
                const player = new PlayerState();
                this.players[pdata.uuid] = player;
                this.player_pid_uuid[pdata.pid] = pdata.uuid;
                return player.init(pdata);
            }),
            this.roleList.init(createRoleList(config, data.players.length)),
            this.usedCharacters.init([]),
            this.budget.init(config.budget),
            this.leaderList.init((_.shuffle(await this.getPlayers()).map(p => p.pid))),
        ]);
        
        this._isInitialized = true;
        this.autoFinish = false;
    }

    async load(sstate: ISerializedGameState): Promise<void> {
        await Promise.all([
            ...sstate.players.map(async (pdata, i) => {
                pdata.pid = `p${i}`;
                const player = new PlayerState();
                this.players[pdata.uuid] = player;
                this.player_pid_uuid[pdata.pid] = pdata.uuid;
                await player.load(pdata);
            }),
            this.roleList.load(sstate.roleList as ROJ.Role[]),
            this.usedCharacters.load(sstate.usedCharacters),
            this.usedDoubleVotes.load(sstate.usedDoubleVotes),
            this.budget.load(sstate.budget),
            this.conceded.load(sstate.conceded),
            this.ready.load(sstate.ready),
        ]);

        for (const sp of sstate.proposals) {
            this.proposals.push(await (new ProposalState().load(sp)));
        }

        for (const spp of sstate.powerplays) {
            this.powerplays[spp.round] = await (new PowerPlayState().load(spp));
        }

        if (sstate.trial) this.trial = await (new TrialState().load(sstate.trial));

        this.gameWinner = sstate.gameWinner as ROJ.Team;

        this.currentPhase = sstate.currentPhase;
        this._isInitialized = true;
        this.autoFinish = false;
    }

    async hostProceed(phase: ROJ.PhaseType) {
        this.proceedPhase = phase;
    }

    async canProceed(): Promise<boolean> {
        return this.proceedPhase === this.currentPhase;
    }

    async inputReady(phase: ROJ.PhaseType) {
        this.inputReadyPhase = phase;
    }

    async isInputReady(): Promise<boolean> {
        return this.currentPhase === 'setup' || this.inputReadyPhase === this.currentPhase;
    }

    async getPlayerByUUID(uuid: string): Promise<PlayerState | undefined> {
        return uuid ? this.players[uuid] : undefined;
    }

    async getPlayerByPID(pid: string): Promise<PlayerState | undefined> {
        const uuid = this.player_pid_uuid[pid];
        return uuid ? this.players[uuid] : undefined;
    }

    async getPlayers(): Promise<ROJ.IPlayer[]> {
        return _.values(await this.mapPlayers(p => p.value()));
    }

    async getPlayerCount(): Promise<number> {
        return _.values(this.players).length;
    }

    async getResignCount(): Promise<number> {
        return this.conceded.size();
    }

    async mapPlayers<T>(mapFn: (player: PlayerState, index: number) => Promise<T>): Promise<{[uuid: string]: T}> {
        const resMap: {[uuid: string]: T} = {};

        let i = 0;
        for (const uuid in this.players) {
            resMap[uuid] = await mapFn(this.players[uuid], i++);
        }

        return resMap;
    }

    async mapPowerplays<T>(mapFn: (powerplay: PowerPlayState, index: number) => Promise<T>): Promise<{[key: string]: T}> {
        const resMap: {[key: string]: T} = {};

        let i = 0;
        for (const key in this.powerplays) {
            resMap[key] = await mapFn(this.powerplays[key], i++);
        }

        return resMap;
    }

    async createRound() {
        const newRound = new RoundState();
        await newRound.init({
            roundNumber: this.rounds.length+1,
            playerCount: _.keys(this.players).length,
        });
        this.rounds.push(newRound);
    }

    async createProposal() {
        const roundNumber = await this.getCurrentRound().getNumber();
        const attempts =
            (await Promise.all(this.proposals.map(async v => v.getRoundNumber())))
            .filter(r => r === roundNumber).length;
        
        // the init we are doing in init() function is not working for some reason
        if (await this.leaderList.size() === 0) await this.leaderList.init((_.shuffle(await this.getPlayers()).map(p => p.pid)));
        const currentLeader = await this.leaderList.get(0);
        const newProposal = new ProposalState();
        await newProposal.init({
            round: roundNumber,
            attempt: attempts+1,
            leaderId: currentLeader,
        });
        await this.leaderList.rotate();

        this.proposals.push(newProposal);
    }

    async createPowerplay() {
        const roundNumber = await this.getCurrentRound().getNumber();
        const newPowerplay = new PowerPlayState();
        await newPowerplay.init({
            round: roundNumber,
        });
        this.powerplays[roundNumber] = newPowerplay;
    }

    async createTrial() {
        this.trial = new TrialState();
        this.trial.init({
            agentId: (await this.getPlayers()).find(p => p.role === 'agent').pid,
            testifierId: (await this.getPlayers()).find(p => p.role === 'testifier').pid,
        });
    }

    getCurrentRound(): RoundState {
        return _.last(this.rounds);
    }

    getCurrentProposal(): ProposalState {
        return _.last(this.proposals);
    }

    async getCurrentPowerplay(): Promise<PowerPlayState | undefined> {
        return this.powerplays[await this.getCurrentRound().getNumber()];
    }

    getTrial(): TrialState {
        return this.trial;
    }
}
