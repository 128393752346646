import _ from "lodash";

import { ROJ } from "gerdoo-api";

export function getRojTermText(term: ROJ.Term, game: ROJ.IControllerGameData): string {
    switch (term.type) {
        case 'player':
            const player = game.players[term.id!];
            return player ? player.name : `invalid:player`;
        
        case 'role':
            const role = game.roles[term.id!];
            return role ? role.name : `invalid:role:${term.id}`;

        case 'var':
            return `${game.vars[`${term.id}`]?.value}`;

        case 'text':
            return `${term.text}`;
    }

    return `[[${term.type}:invalid]]`;
};

// export const RojTerm: React.FC<ROJ.Term> = (term) => {
//     const { gameData: game, inbox } = useSelector((state: IControllerState) => state);

//     switch (term.type) {
//         case 'player':
//             const player = game.players[term.id!];
//             return player ? <PlayerChip {...player} /> : <div>{`invalid:player:${term.id}`}</div>;
        
//         case 'power':
//             const power = game.powers[term.id!];
//             return power ? <PowerChip power={power.id} /> : <div>{`invalid:power:${term.id}`}</div>;
        
//         case 'role':
//             const role = game.roles[term.id!];
//             return role ? <RoleChip role={role.id} /> : <div>{`invalid:role:${term.id}`}</div>;

//         case 'powerplay':
//             if (term.property) {
//                 const powerplay = game.powerplays[term.id!];
//                 const value: string = (powerplay as any)[term.property];
//                 let secret: ROJ.IGameSecret | undefined;
//                 if (value?.startsWith('secret')) {
//                     const secretId = value.split('.')[1];
//                     if (secretId) {
//                         secret = inbox.secrets[secretId];
//                     }
//                 }
                
//                 let unknownSecretType: ROJ.GameSecretType | undefined = undefined;
//                 if (!secret) {
//                     switch (term.property) {
//                         case 'actorId': unknownSecretType = 'powerplay_actor'; break;
//                         case 'targetId': unknownSecretType = 'powerplay_target'; break;
//                         case 'result': unknownSecretType = 'powerplay_result'; break;
//                     }
//                 }

//                 if (unknownSecretType) {
//                     secret = {type: unknownSecretType, data: powerplay ? {powerplayId: powerplay.id} as any : undefined} as any;
//                 }
                
//                 if (secret) return <SecretChip secret={secret} />;
//                 return <>{`[powerplay.invalidprop(${term.property})]`}</>;
//             }
//             break;

//         case 'var':
//             return <span>{game.vars[`${term.id}`]?.value}</span>;

//         case 'text':
//             return <span>{term.text}</span>;
            
//     }

//     return <span>[[{term.type}:invalid]]</span>;
// };
