import { useContext } from "react";
import { IonAvatar, IonBadge, IonCard, IonChip, IonLabel } from "@ionic/react";
import styled from "styled-components";

import { ROJ } from 'gerdoo-api';
import { AppContext } from "../apps/ControllerApp";
import { useSelector, useStore } from "react-redux";
import { IControllerState } from "gerdoo-controller-connector";

export interface IRojInteractable {
    redacted?: boolean;
    disabled?: boolean;
    selected?: boolean;
    onClick?: () => void;
    size?: number;
    badge?: 'checkmark' | 'maybe' | 'grey';
}

export interface IRojAvatarImgProps extends IRojInteractable {
    img: JSX.Element;
    caption?: string;
    noFrame?: boolean;
}

export interface IRojEntityRef {
    type: ROJ.GameEntity;
    id?: string;
}

export const RojAvatar: React.FC<IRojAvatarImgProps> = (props) => {
    const { img, caption, disabled, redacted, onClick, selected, size, noFrame, badge } = props;

    const baseStyle = { position: 'relative', width: size || 80, height: size || 80, marginBottom: 5 };
    const varStyle = noFrame ? {
        backgroundColor: redacted ? 'black' : 'transparent',
        borderRadius: 0,
    } : {
        opacity: disabled && !selected ? 0.5 : 1.0,
        backgroundColor: selected ? 'green' : 'black',
        border: selected ? 'thick solid darkgreen' : 'thick solid grey',
    };

    return <AvatarDiv onClick={() => { if (!disabled && onClick) onClick() }}>
        <IonAvatar
            className="ion-text-center"
            style={{ ...baseStyle, ...varStyle }}
        >
            {!redacted && img}
            { badge === 'checkmark' && <IonBadge color='success' style={{position: 'absolute', bottom: 0, right: 0, borderRadius: 20, width: 20, height: 20}}>✓</IonBadge> }
            { badge === 'maybe' && <IonBadge color='warning' style={{position: 'absolute', bottom: 0, right: 0, borderRadius: 20, width: 20, height: 20}}>!</IonBadge> }
            { badge === 'grey' && <IonBadge color='light' style={{position: 'absolute', bottom: 0, right: 0, borderRadius: 20, width: 20, height: 20}}>?</IonBadge> }
        </IonAvatar>
        <IonLabel color={selected ? 'success' : ''} className="ion-text-nowrap">{redacted ? '▒▒▒▒▒' : (caption || '')}</IonLabel>
    </AvatarDiv>;
}

export const RojImage: React.FC<IRojEntityRef & {style?: {}}> = (props) => {
    const { cdn } = useContext(AppContext);
    const { gameData } = useSelector((state: IControllerState) => state);
    const { type, id, style } = props;

    let img = <img src={`${cdn}/characters/passport/anon-white.png`} />;
    if (!id) return img;

    switch (type) {
        case 'player':
            const player = gameData.players[id] as ROJ.GameEntityAttrMap['player'];
            if (player) {
                img = <img style={{...style||{}, ...{ transform: 'scale(1.2) translateY(-5px)' }}} src={`${cdn}/characters/passport/${player.character ? `${player.character}-photo` : `anon-white`}.png`} />;
            } else {
                img = <img style={style||{}} src={`${cdn}/characters/passport/anon-white.png`} />;
            }
            break;

        case 'character':
            img = <img style={style||{}} src={`${cdn}/characters/passport/${id ? `${id}-photo` : `anon-white`}.png`} />;
            break;

        case 'power':
        case 'role':
        case 'team':
            img = <img style={{...style||{}, ...{borderRadius: 0}}} src={`${cdn}/game-icons/${id || 'unknown'}.png`} />;
            break;
    }

    return img;
}

export const RojCard: React.FC<IRojAvatarImgProps> = (props) => {
    const { img, caption, selected, disabled, onClick, size } = props;

    return <IonCard
        onClick={() => { if (!disabled && onClick) onClick() }}
        color={disabled ? 'dark' : selected ? 'success' : ''}
        style={{ width: size || 80 }} className='ion-padding ion-text-center ion-justify-content-center'>
        <IonAvatar className="ion-margin-bottom">
            {img}
        </IonAvatar>
        {caption && <IonLabel>{caption}</IonLabel>}
    </IonCard>;
}

const AvatarDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
