import _ from "lodash";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonIcon, IonLabel, IonRow, IonText } from "@ionic/react";
import { arrowForward, warning } from "ionicons/icons";

import { getRoleDescription, getRoleDisplayName, getSecretValue, getTeamDescription, getTeamDisplayName, reinstatePlayer } from "roj-common";
import { IControllerState } from "gerdoo-controller-connector";

import { AppContext } from "../../apps/ControllerApp";
import { RojAvatar, RojImage } from "../RojAvatar";
import { _print } from "../InboxTab";

export const InboxSecretCard: React.FC<{}> = ({ }) => {
    const { connector } = useContext(AppContext);
    const { gameData, inbox, actionPanel, me } = useSelector((state: IControllerState) => state);

    const player = reinstatePlayer(me.pid, gameData, inbox);
    
    const leakerSecrets = _.values(inbox.secrets).filter(s => s.type === 'player_team' && s.data.team === 'leakers');
    const leakers = leakerSecrets.map(s => (s.data as any).pid).map(pid => reinstatePlayer(pid, gameData, inbox)).filter(p => !!p);

    const canResign = _.keys(actionPanel.input).indexOf('concede') >= 0;
    const hasResigned = actionPanel.input['concede']?.value;

    return <IonRow>
        <IonCard>
            <IonCardContent className="ion-padding" style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between' }}>
                <div>
                    <RojAvatar size={60} noFrame img={<RojImage type="team" id={player?.team} />} />
                </div>
                <div style={{marginLeft: 10}}>
                    <IonCardTitle><IonLabel color='medium'>Your Team:</IonLabel> {_print(getTeamDisplayName(player?.team!), gameData)}</IonCardTitle>
                    <IonCardSubtitle>{_print(getTeamDescription(player?.team!), gameData)}</IonCardSubtitle>
                </div>
            </IonCardContent>
        </IonCard>

        <IonCard>
            <IonCardContent className="ion-padding" style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between' }}>
                <div>
                    <RojAvatar size={60} noFrame img={<RojImage type="role" id={player?.role} />} />
                </div>
                <div style={{marginLeft: 10}}>
                    <IonCardTitle><IonLabel color='medium'>Your Role:</IonLabel> {_print(getRoleDisplayName(player?.role!), gameData)}</IonCardTitle>
                    <IonCardSubtitle>{_print(getRoleDescription(player?.role!), gameData)}</IonCardSubtitle>
                </div>
            </IonCardContent>
        </IonCard>

        { player?.role === 'agent' && <IonCard style={{width: '100%'}}>
            <IonCardContent className="ion-padding" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <IonText className="ion-padding"><b>As the Agent, you know the Leakers are: </b></IonText>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    {leakers.map(leaker => <RojAvatar caption={leaker?.name} img={<RojImage type="player" id={leaker?.pid} />} />)}
                </div>
            </IonCardContent>
        </IonCard> }

        { player?.team === 'leakers' && <IonCard style={{width: '100%'}}>
            <IonCardContent className="ion-padding" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <IonText className="ion-padding"><b>As a Leaker, you know the Leakers are: </b></IonText>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    {leakers.map(leaker => <RojAvatar caption={leaker?.name} img={<RojImage type="player" id={leaker?.pid} />} />)}
                </div>
            </IonCardContent>
        </IonCard> }

        {/* <IonCol size="12" className="ion-text-center">
            <IonButton routerLink="/directory">Check Directory for more clues <IonIcon icon={arrowForward} /></IonButton>
        </IonCol> */}

        { player?.team === 'leakers' && (canResign || hasResigned) && <IonCard>
            <IonCardHeader>
                <IonCardTitle style={{display: 'flex'}}><IonIcon color="warning" icon={warning} />Group-Resign?</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonRow>
                    <IonText>
                        If all Leakers vote to resign as a group, they skip to court to try exposing the Agent. Use this only as a last resort, when there is no chance to win by sabotaging the missions.
                    </IonText>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-margin-top">
                    {!canResign && <IonButton disabled>Not Available</IonButton>}
                    {canResign && !hasResigned && <IonButton onClick={() => connector.sendPlayerInput('concede', 'tap.done', {})}>Vote to Group-Resign</IonButton>}
                    {canResign && hasResigned && <IonText>You have already agreed to group-resign.</IonText>}
                </IonRow>
                <IonRow className="ion-justify-content-center">{`${inbox.resignCount || 0}/${gameData.vars['leakercount'].value} Leakers have agreed.`}</IonRow>
            </IonCardContent>
        </IonCard>}
    </IonRow>;
};
