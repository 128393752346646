import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROJ } from 'gerdoo-api';

function generateInboxRows(gameState: ROJ.IControllerGameState, gameData: ROJ.IControllerGameData, inbox: ROJ.IControllerInbox): ROJ.IInboxRow[] {
  const rows: ROJ.IInboxRow[] = [];

  const secretFileEntries = _.values(inbox.secrets).filter(s => s.category === 'secretfile');
  if (!_.isEmpty(secretFileEntries)) {
    rows.push({
      id: 'secretfile',
      type: 'secretfile',
      data: { secretIds: secretFileEntries.map(s => s.id) },
      h1: 'Your Secret File',
      h2: 'Life deals you a card',
      icon: 'folder',
      badge: undefined,
    });
  }

  for (let Rn = 1; Rn <= (gameState?.round?.number || 0); Rn++) {
    const round = _.values(gameData.rounds).find(r => r.number === Rn);
    if (round) {
      let badge = { color: 'primary', text: 'IN ACTION' };
      let h2 = `${gameState.phase}...`;
      if (round.isReported) {
        if (round.isLeaked) {
          badge = { color: 'danger', text: 'LEAKED' };
          h2 = 'Sabotaged by Leakers';
        } else if (round.isFinished) {
          badge = { color: 'tertiary', text: 'COVERED UP' };
          h2 = 'Loyalists covered it up';
        }
      }

      rows.push({
        id: `m${round.id}`,
        type: 'round',
        data: round,
        h1: 'Mission #' + round.number,
        h2,
        icon: `folder${round.number}`,
        isActive: !round.isFinished,
        badge,
      });
    }

    const powerplay = _.values(gameData.powerplays).find(pp => pp.round === Rn);
    if (powerplay) {
      const isActive = round?.number === gameState?.round.number && gameState.phase === 'powerplay';
      rows.push({
        id: powerplay.id,
        type: 'powerplay',
        data: powerplay,
        h1: powerplay.type,
        h2: 'A Loyalist goes rogue',
        icon: powerplay.type,
        badge: isActive ? { color: 'secondary', text: 'IN ACTION' } : undefined,
        isActive,
      })
    }
  }

  if (gameState.trial) {
    rows.push({
      id: 'trial',
      type: 'trial',
      data: gameState.trial,
      h1: 'Trial',
      h2: 'Leakers may expose the Agent',
      icon: 'folder',
      badge: undefined,
    });
  }

  if (gameState.phase === 'summary') {
    rows.push({
      id: 'summary',
      type: 'summary',
      data: {winner: gameState.winner, leakerIds: gameState.revealedLeakers},
      h1: 'Final Report',
      h2: 'The curtain is pulled back',
      icon: 'folder',
      badge: undefined,
    });
  }

  if (_.last(rows)) _.last(rows).isLast = true;

  return rows;
}

const inboxSlice = createSlice({
  name: 'inbox',
  initialState: {
    resignCount: 0,
    secrets: {},
    rows: [],
  } as ROJ.IControllerInbox,
  reducers: {
    update: (state, action: PayloadAction<{ inbox: ROJ.IControllerInbox, gameData: ROJ.IControllerGameData, gameState: ROJ.IControllerGameState }>) => {
      const { gameState, gameData, inbox } = action.payload;
      const rows = generateInboxRows(gameState, gameData, inbox);
      return {
        ...state,
        ...inbox,
        rows,
      };
    }
  },
});

export const inboxReducer = inboxSlice.reducer;
export const inboxActions = { ...inboxSlice.actions };
