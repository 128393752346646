import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROJ } from 'gerdoo-api';

const initialState: ROJ.IControllerGameState = {
  phase: undefined!,
  round: undefined!,
  proposal: undefined!,
};

const gameStateSlice = createSlice({
  name: 'gameStaet',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<ROJ.IControllerGameState>) => {
      return {
        ...state,
        ...action.payload,
      };
    }
  },
});

export const gameStateReducer = gameStateSlice.reducer;
export const gameStateActions = { ...gameStateSlice.actions };
