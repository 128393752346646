import _ from "lodash";
import Immutable from "immutable";
import { ROJ } from "gerdoo-api";

import { IGameStateChunk } from ".";

export class TrialState implements IGameStateChunk<ROJ.ITrial> {
    private _trial: Immutable.Map<keyof ROJ.ITrial, string | number | boolean>;

    async key(): Promise<string> {
        return `trial`;
    }

    async value(): Promise<ROJ.ITrial> {
        return this._trial.toJS() as any as ROJ.ITrial;
    }

    async init(data: ROJ.ITrialInitialData) {
        this._trial = Immutable.Map<keyof ROJ.ITrial, string | number>({
            testifierId: data.testifierId,
            agentId: data.agentId,
        });
    }

    async load(trial: ROJ.ITrial): Promise<TrialState> {
        this._trial = Immutable.Map<keyof ROJ.ITrial, string | boolean | number>(trial);
        return this;
    }

    async clear() {
        this._trial.clear();
    }

    async getTestifierId(): Promise<string> {
        return this._trial.get('testifierId') as string;
    }

    async getTargetId(): Promise<string> {
        return this._trial.get('targetId') as string;
    }

    async getSusId(): Promise<string> {
        return this._trial.get('susId') as string;
    }

    async getAgentId(): Promise<string> {
        return this._trial.get('agentId') as string;
    }

    async setSus(pid: string) {
        this._trial = this._trial.set('susId', pid);
    }

    async confirm(pid: string) {
        this._trial = this._trial.set('targetId', pid).set('confirmed', true);
    }

    async isConfirmed() {
        return this._trial.get('confirmed') as boolean;
    }

    async isAgentExposed(): Promise<boolean> {
        const agentId = await this.getAgentId();
        const targetId = await this.getTargetId();
        const isConfirmed = await this.isConfirmed();

        return isConfirmed && !!targetId && targetId === agentId;
    }
}
