import _ from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { IonContent, IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { mail, people } from 'ionicons/icons';

import { ControllerHeader } from './ControllerHeader';
import { InboxMessage, InboxTab } from './InboxTab';
import { ActionOverlay } from './ActionOverlay';
import { DirectoryTab } from './DirectoryTab';
import { IControllerState } from 'gerdoo-controller-connector';
            
export const ControllerComponent: React.FC = () => {
    // const { gameState } = useSelector((state: IControllerState) => state);
    // const history = useHistory();

    // useEffect(() => {
    //     if (gameState.phase === 'setup') {
    //         history.push('/directory');
    //     }
    // }, [gameState.phase]);

    return (
        // <IonTabs>
            <IonRouterOutlet id='lobby'>
                <IonContent>
                    <ActionOverlay />
                    {/* <ControllerHeader /> */}
                    <Redirect path="/" to="/inbox" />
                    {/* <Route path="/directory" component={DirectoryTab} /> */}
                    <Route path="/inbox" component={InboxTab} exact />
                    <Route path="/inbox/:id" component={InboxMessage} />
                    {/* <Route path="/tabs/settings" component={MyProfilePage} /> */}
                </IonContent>
            </IonRouterOutlet>

            // <IonTabBar slot='bottom' selectedTab='action'>
            //     <IonTabButton tab='inbox' href='/inbox'><IonIcon icon={mail} />Inbox</IonTabButton>
            //     <IonTabButton tab='directory' href='/directory'><IonIcon icon={people} />Directory</IonTabButton>
            // </IonTabBar>
        // </IonTabs>
    );
}
