import _ from "lodash";
import Immutable from "immutable";
import { getRoundConfig } from "gerdoo-util";
import { ROJ } from "gerdoo-api";

import { IGameStateChunk } from ".";

export class RoundState implements IGameStateChunk<ROJ.IRound> {
    private _round: Immutable.Map<keyof ROJ.IRound, any>;
    private _squad: Immutable.Set<string>;
    private _tempPlays: Immutable.Map<string, boolean>;
    private _plays: Immutable.Map<string, boolean>;

    async key(): Promise<string> {
        return `round:${await this.getNumber()}`;
    }

    async value(): Promise<ROJ.IRound> {
        const base = this._round.toJS() as any;

        return {
            ...base,
            plays: this._plays.toJS(),
            squad: this._squad.toArray(),
        };
    }

    async init(data: ROJ.IRoundInitialData) {
        const roundConfig = getRoundConfig(data.playerCount)[data.roundNumber-1];
        
        this._round = Immutable.Map<keyof ROJ.IRound, any>({
            id: `${data.roundNumber}`,
            number: data.roundNumber,
            secrecy: roundConfig.secrecy,
            squadSize: roundConfig.squadSize,
        });
        this._tempPlays = Immutable.Map({});
        this._plays = Immutable.Map({});
        this._squad = Immutable.Set([]);
    }

    async load(round: ROJ.IRound): Promise<RoundState> {
        this._round = Immutable.Map<keyof ROJ.IRound, any>({
            number: round.number,
            squadSize: round.squadSize,
            secrecy: round.secrecy,
            isFinished: round.isFinished,
            isLeaked: round.isLeaked,
        });
        this._tempPlays = Immutable.Map<string, boolean>(round.plays);
        this._squad = Immutable.Set<string>(round.squad);

        return this;
    }

    async clear() {
        this._round.clear();
    }
    
    async getNumber(): Promise<number> {
        return this._round.get('number') as number;
    }

    async getSquadSize(): Promise<number> {
        return this._round.get('squadSize');
    }

    async getSecrecy(): Promise<number> {
        return this._round.get('secrecy');
    }

    async getSquad(): Promise<string[]> {
        return this._squad.toArray();
    }
    
    async tempPlay(pid: string, value: boolean) {
        this._tempPlays = this._tempPlays.set(pid, value);
    }

    async confirmPlay(pid: string) {
        this._plays = this._plays.set(pid, this._tempPlays.get(pid));
    }

    async getTempPlays(): Promise<{[pid: string]: boolean}> {
        return this._tempPlays.toJS() as {[pid: string]: boolean};
    }

    async getPlays(): Promise<{[pid: string]: boolean}> {
        return this._plays.toJS() as {[pid: string]: boolean};
    }

    async setSquad(squad: string[]): Promise<void> {
        this._squad = Immutable.Set(squad);
    }

    async getFailPlayers(): Promise<string[]> {
        return this._round.get('failPlayers');
    }

    async setResult(leaked: boolean) {
        var failPlayers: string[] = [];
        this._plays.forEach((value: boolean, key: string) => {
            if (!value) failPlayers.push(key);
        });

        this._round = this._round
            .set('failPlayers', failPlayers)
            .set('isLeaked', leaked)
            .set('isFinished', true);
    }

    async markReported() {
        this._round = this._round.set('isReported', true);
    }

    async isFinished(): Promise<boolean> {
        return this._round.get('isFinished');
    }

    async isLeaked(): Promise<boolean> {
        return this._round.get('isLeaked');
    }

    async isReported(): Promise<boolean> {
        return this._round.get('isReported');
    }
}
