import React from "react";
import { IonButton, IonCol } from "@ionic/react";
import { APInputDataType, APInputOf, APTapInputDataType, ROJ } from "gerdoo-api";

export interface ITapInputProps {
    game: ROJ.IControllerGameData;
    input: APInputOf<'tap'>;
    onAction: <ACTION extends keyof APTapInputDataType>(action: ACTION, data: APInputDataType[ACTION]) => void;
}

export const TapInput: React.FC<ITapInputProps> = ({game, input, onAction}) => {
    const { args, value } = input;
    const { disabled, label } = args;

    return <IonCol className='ion-text-center ion-padding-top'>
        <IonButton expand='block' disabled={disabled} onClick={() => onAction('tap.done', {})}>{ label || 'OK' }</IonButton>
    </IonCol>;
}
