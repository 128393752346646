import { ROJ } from 'gerdoo-api';

import { getAllCharacters, getTeamByRole, createRoleList } from 'gerdoo-util';

const mockNames = ['abby', 'bobby', 'cece', 'dave', 'essy', 'fido', 'gale', 'hugh', 'ian', 'jian', 'kian'];
const characters = getAllCharacters();

export function createPlayers(playerCount: number, config: ROJ.IGameConfig): ROJ.IPlayer[] {
    const roleList = createRoleList(config, playerCount);
    const names = mockNames.slice(0, playerCount);
    
    return names.map((name, i) => ({
        name,
        pid: `pid-${name}`,
        uuid: `uuid-${name}`,
        team: getTeamByRole(roleList[i]),
        role: roleList[i],
        character: characters[i],
    }));
}
