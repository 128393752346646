import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROJ } from 'gerdoo-api';

const initialState: ROJ.IControllerGameData = {
  characters: {},
  rounds: {},
  players: {},
  roles: {},
  teams: {},
  powerplays: {},
  vars: {},
};

const gameDataSlice = createSlice({
  name: 'gameData',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<ROJ.IControllerGameData>) => {
      return {
        ...state,
        ...action.payload,
      };
    }
  },
});

export const gameDataReducer = gameDataSlice.reducer;
export const gameDataActions = { ...gameDataSlice.actions };
