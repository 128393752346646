import { v4 as uuidv4 } from 'uuid';

import { IonContent, IonPage } from '@ionic/react';
import Cookies from 'js-cookie';

import { useControllerConnector } from 'gerdoo-controller-connector';
import { GERDOO_COOKIE_CLIENT_SID } from 'gerdoo-util';

import { ControllerApp } from './ControllerApp';
import { JoinForm } from '../components/JoinForm2';

const authEndpoint = process.env.REACT_APP_GERDOO_AUTH!;
let playerSID: string = Cookies.get(GERDOO_COOKIE_CLIENT_SID)!;

if (!playerSID) {
    playerSID = uuidv4();
    Cookies.set(GERDOO_COOKIE_CLIENT_SID, playerSID);
}

export const ControllerAppRoot: React.FC = () => {
    const connector = useControllerConnector(playerSID, authEndpoint, false);
    
    if (!connector) return <>Connecting...</>;

    return <><ControllerApp connector={connector} /></>;
}
