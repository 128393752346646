import _ from "lodash";

export const GERDOO_COOKIE_CLIENT_SID = 'gerdoo-cookie-client-sid';

export function tryParseJson(json: string): any {
  let obj: any;
  try {
    obj = JSON.parse(json);
  }
  catch (e) {
    console.error(`tryParseJson failed.`)
    console.error(e?.stack || e);
  }
  finally {
    return obj;
  }
}

export function waitUntil(
  condition: () => boolean,
  timeout = 3000,
): Promise<boolean> {
  return new Promise<boolean>(resolve => {
    const INTERVAL = 100;
    let cnt = timeout / INTERVAL;
    const handle = setInterval(() => {
      if (condition()) {
        resolve(true);
        clearInterval(handle);
      } else if (cnt-- <= 0) {
        resolve(false);
        clearInterval(handle);
      }
    }, INTERVAL);
  });
}

export function repeatUntil(
  repeat: () => void,
  condition: () => boolean,
  interval = 500,
  timeout = 5000,
): Promise<boolean> {
  return new Promise<boolean>(resolve => {
    let cnt = timeout / interval;
    repeat();
    const handle = setInterval(() => {
      if (condition()) {
        resolve(true);
        clearInterval(handle);
      } else if (cnt-- <= 0) {
        resolve(false);
        clearInterval(handle);
      } else {
        repeat();
      }
    }, interval);
  });
}

export function generateRandomCode(length: number): string {
  const chars = 'BCDFGHKLMNPQRSTVXZ';
  let code = '';
  for (let i = 0; i < length; i++)
      code += chars[_.random(0, chars.length - 1)];

  return code;
}