import _ from "lodash";
import Immutable from "immutable";
import { ROJ } from 'gerdoo-api';

import { IGameStateChunk } from ".";

export class PowerPlayState implements IGameStateChunk<ROJ.IPowerPlay> {
    private _powerplay: Immutable.Map<keyof ROJ.IPowerPlay, any>;

    async key(): Promise<string> {
        return `powerplay:${this.getRoundNumber()}`;
    }

    async value(): Promise<ROJ.IPowerPlay> {
        return this._powerplay.toJS() as any as ROJ.IPowerPlay;
    }

    async init(data: ROJ.IPowerPlayInitialData) {
        this._powerplay = Immutable.Map<keyof ROJ.IPowerPlay, string | number>({
            round: data.round,
            actingTeam: 'loyals',
        });
    }

    async load(powerplay: ROJ.IPowerPlay): Promise<PowerPlayState> {
        this._powerplay = Immutable.Map<keyof ROJ.IPowerPlay, any>(powerplay);
        return this;
    }

    async clear() {
        this._powerplay.clear();
    }

    async getRoundNumber(): Promise<number> {
        return this._powerplay.get('round') as number;
    }

    async getType(): Promise<ROJ.PowerType> {
        return this._powerplay.get('type') as ROJ.PowerType;
    }

    async getActingTeam(): Promise<ROJ.Team> {
        return this._powerplay.get('actingTeam') as ROJ.Team;
    }

    async getActorId(): Promise<string> {
        return this._powerplay.get('actorId') as string;
    }

    async getTargetId(): Promise<string> {
        return this._powerplay.get('targetId') as string;
    }

    async getTargetId2(): Promise<string> {
        return this._powerplay.get('targetId2') as string;
    }

    async getResult(): Promise<string> {
        return this._powerplay.get('result') as string;
    }

    async assign(actorId: string, type: ROJ.PowerType): Promise<void> {
        this._powerplay = this._powerplay
            .set('actorId', actorId)
            .set('type', type)
            .set('id', `pp-${this._powerplay.get('round')}-${type}`);
    }

    async setTargetId(targetId: string): Promise<void> {
        this._powerplay = this._powerplay
            .set('targetId', targetId)
    }

    async confirm(): Promise<void> {
        this._powerplay = this._powerplay
            .set('isUsed', true);
    }

    async finish(result: string): Promise<void> {
        this._powerplay = this._powerplay
            .set('isFinished', true)
            .set('result', result);
    }

    // async setResult(result: string): Promise<void> {
    //     this._powerplay = this._powerplay
    //         .set('result', result)
    // }

    // async setNotify(result: string): Promise<void> {
    //     this._powerplay = this._powerplay
    //         .set('notify', result);
    // }
}
