import { Reducer, AnyAction } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GerdooAuthToken, ILobbyConfig, ILobbyGuest, LobbyHostUpdateArgsMap, LobbyPlayerUpdateArgsMap, ROJ } from 'gerdoo-api';
import { createRoleList } from 'gerdoo-util';

export type IControllerLobbyState = LobbyPlayerUpdateArgsMap['lobby.refresh'];

const initialState: IControllerLobbyState = {
    me: undefined!,
    others: [],
};

const lobbySlice = createSlice({
    name: 'lobby',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        update: (state, action: PayloadAction<LobbyPlayerUpdateArgsMap['lobby.refresh']>) => {
            return {
                ...state,
                ...action.payload,
            }
        },
    },
    extraReducers: builder => {
    }
});

export const lobbyReducer: Reducer<IControllerLobbyState, AnyAction> = lobbySlice.reducer;
export const lobbyActions = {
    ...lobbySlice.actions,
};
