import { IonCheckbox, IonCol, IonIcon, IonItem, IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { thumbsDown, thumbsUp } from "ionicons/icons";

import { APCheckboxInputDataType, APInputDataType, APInputOf, ROJ } from "gerdoo-api";

export interface ICheckboxInputProps {
    game: ROJ.IControllerGameData;
    input: APInputOf<'checkbox'>;
    onAction: <ACTION extends keyof APCheckboxInputDataType>(action: ACTION, data: APInputDataType[ACTION]) => void;
}


export const CheckBoxInput: React.FC<ICheckboxInputProps> = ({game, input, onAction}) => {
    const { args, value } = input;
    const { disabled, label } = args;

    return <IonCol className='ion-text-center ion-padding-top'>
        <IonItem>
            <IonCheckbox slot="start" value={value} disabled={disabled} onIonChange={(evt) => onAction('checkbox.update', { checked: evt.target.checked })} />
            <IonLabel>{label}</IonLabel>
        </IonItem>
    </IonCol>
};

// export const VotingInput: React.FC<ICheckboxInputProps> = ({game, input, onAction}) => {
//     return <IonCol className='ion-text-center ion-padding-top'><IonSegment>
//         <IonSegmentButton onClick={() => onAction('checkbox.update', {checked: true})}><IonIcon icon={thumbsUp} /></IonSegmentButton>
//         <IonSegmentButton><IonIcon icon={thumbsDown} /></IonSegmentButton>
//     </IonSegment></IonCol>
// };
