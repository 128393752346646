import _ from 'lodash';
import { ROJ } from 'gerdoo-api';

export class GameConfigBuilder {
    private _config: ROJ.IGameConfig;

    constructor() {
        this._config = {
            roles: ['agent', 'testifier'],
            budget: 10,
            pw1pool: ['confide'],
            pw2pool: ['wiretap'],
        };
    }

    build(): ROJ.IGameConfig {
        return this._config;
    }

    withRoles(roles: ROJ.Role[]): GameConfigBuilder {
        this._config.roles = roles;
        return this;
    }
}

export class PlayerListBuilder {
    _players: ROJ.IPlayer[] = [];
    _characters: string[] = ['tiger', 'rhino', 'rabbit', 'owl', 'wolf', 'hippo'];

    constructor(playerCount: number) {
        this._players = _.range(0, playerCount).map(i => ({
            uuid: `p${i}-uuid`,
            pid: `p${i}-id`,
            name: `p${i}-name`,
            character: this._characters[i],
        }));
    }

    build() {
        return this._players;
    }
}
