
import Axios from 'axios'
import Cookies from 'js-cookie';
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GerdooAuthToken } from "gerdoo-api";
import { IControllerState } from 'src/ControllerConnector';

/**
 * iOS has issues supporting cors cookies, so the session doesn't work and the native fetch also doesn't set the headers
 * We set our own headers using axios to send the rejoin info to server
 */
function getRequestHeaders() {
  const headers: any = {
    'Content-Type': 'application/json',
  };

  return headers;
}

export const authenticate = createAsyncThunk<
  { authToken: GerdooAuthToken }, // return type of playload creator
  { server: string }, // first argument to the payload creator
  { state: IControllerState }
>('authenticate', async (args, thunkApi) => {
  const { server } = args;
  const headers = getRequestHeaders();
  const response = await Axios.get(`${server}/auth`, {
    headers,
  });

  if (response.status === 200) {
    const authToken = response.data as GerdooAuthToken;
    if (authToken) return { authToken };
  }

  // Cookies.remove(PLAYER_ID_COOKIE_NAME);
  return thunkApi.rejectWithValue(undefined);
});
