import _ from 'lodash';
import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import QueryString from 'query-string';

import { IHostState } from 'gerdoo-host-connector';
import { IControllerState, useControllerConnector } from 'gerdoo-controller-connector';
import { APInput, IPubSubKeys, ROJ } from 'gerdoo-api';

import { TestControllerArray } from '../components/test-console/TestControllerArray';

import './TestConsole.scss';

const queryJoinCode = (QueryString.parse(document.location.search) as any)['join'] || '';
const useLocalPubsub = process.env.REACT_APP_GERDOO_ENV === 'dev';
const sid = (i: number, code: string) => `s${i}-${code}`;

const authEndpoint = process.env.REACT_APP_GERDOO_AUTH!;
const pubsubKeys: IPubSubKeys = {
    subscribeKey: process.env.REACT_APP_PN_SUBKEY!,
    publishKey: process.env.REACT_APP_PN_PUBKEY!,
};

export const TestConsole: React.FC<{ onStateChange: (state: IHostState) => void }> = (props) => {
    const [roomCodeText, setRoomCodeText] = useState(queryJoinCode);
    const [roomCode, setRoomCode] = useState(queryJoinCode);
    
    const c1 = useControllerConnector(sid(0, roomCode), authEndpoint, useLocalPubsub)!;
    const c2 = useControllerConnector(sid(1, roomCode), authEndpoint, useLocalPubsub)!;
    const c3 = useControllerConnector(sid(2, roomCode), authEndpoint, useLocalPubsub)!;
    const c4 = useControllerConnector(sid(3, roomCode), authEndpoint, useLocalPubsub)!;
    const c5 = useControllerConnector(sid(4, roomCode), authEndpoint, useLocalPubsub)!;
    const c6 = useControllerConnector(sid(5, roomCode), authEndpoint, useLocalPubsub)!;

    const cstates = [c1?.state, c2?.state, c3?.state, c4?.state, c5?.state, c6?.state];

    const [jsonViewTab, setJsonViewTab] = useState('host');

    let json: any = jsonViewTab === 'host' ? {} : undefined;
    if (!json) {
        json = cstates.find(c => c?.connection?.authToken?.uuid === jsonViewTab);
    }

    return (<div>
        <div style={{ width: '100%' }}>
            { !roomCode && <div className='toolbar'>
                <input type={'text'} value={roomCodeText} onChange={(e) => setRoomCodeText(e.target.value)} style={{width:100, fontSize: 26}} />
                <button onClick={() => setRoomCode(roomCodeText)}>Connect</button>
            </div> }
            {/* { selfHost && <HostToolbar /> } */}
            { roomCode && <TestControllerArray roomCode={roomCode} controllers={[c1,c2,c3,c4,c5,c6]} /> }
        </div>
        {/* <div style={{ width: '35%', height: '100%', position: 'absolute', top: 0, right: 0, background: `rgb(35, 35, 33)`, padding: '20px' }}>
            <JsonToolbarDiv style={{ display: 'flex', marginBottom: '10px' }}>
                <button className={jsonViewTab === 'host' ? 'active' : ''} onClick={() => setJsonViewTab('host')}>Host</button>
                {cstates.map((cstate, i) => <button key={i}
                        className={jsonViewTab === cstate?.connection.authToken?.uuid ? 'active' : ''}
                        onClick={() => setJsonViewTab(cstate?.connection.authToken?.uuid || 'host')}
                    >{cstate?.room?.me?.name}</button>)}
            </JsonToolbarDiv>
            <div style={{ height: '100%', msOverflowY: 'scroll' }}>
                <ReactJson src={json} theme='monokai' />
            </div>
        </div> */}
    </div>);
}

export function getActionSummary(action: any) {
    return action ? `${action?.action}: ${JSON.stringify(action?.data)}` : '-';
}

const JsonToolbarDiv = styled.div`
    button {
        border: thick solid transparent;

        &.active {
            border: thick solid black;
            background-color: #4444ff;
            color: white;
        }
    }
`;

// TODO: move this to gerdoo-util and type it properly
export function getRandomInputValue(player: ROJ.IPlayer, game: ROJ.IControllerGameData, input: APInput): [string, any] | undefined {
    if (!input) return;

    switch (input.type) {
        case 'picker':
            let picked = null;
            const collection = game[`${input.args.collectionId}`];

            if (input.value.filter(v => !!v).length < input.args.count) {
                let options: string[] = (input.args.options === 'all' ? _.values(collection).map(p => p.id) : input.args.options);
                options = options.filter(opt => input.value.indexOf(opt) < 0);
                picked = options[_.random(0, options.length - 1)];
            }

            return picked ? ['picker.pick', { id: picked }] : undefined;

        case 'radio':
            if (input.args.options.find(o => o.id === 'upvote')) {
                return ['radio.pick', { id: 'upvote' }];
            } else if (input.args.options.find(o => o.id === 'leak')) {
                return ['radio.pick', { id: player.team === 'loyals' ? 'coverup' : (Math.random() > 0.7 ? 'leak' : 'coverup') }];
            }
            break;

        case 'tap':
            if (!input.value && !input.args.disabled) {
                return ['tap.done', {}];
            }
    }

    return;
}
