import Axios from 'axios';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { APInputDataType, GerdooAuthToken, IGerdooPlayerCommand, IGerdooPlayerUpdate, ILobbyJoinCommand, ILobbyLeaveCommand, IPubSubProvider, ROJ } from 'gerdoo-api';
import { PubNubProvider } from 'gerdoo-pubsub';
import { waitUntil } from 'gerdoo-util';

import { actionPanelActions, actionPanelReducer, connectionActions, connectionReducer, directoryActions, directoryReducer, gameDataActions, gameDataReducer, gameStateActions, gameStateReducer, IConnectionState, IControllerActionPanel, IControllerLobbyState, IControllerNavState, IControllerRoomState, inboxActions, inboxReducer, lobbyActions, lobbyReducer, meActions, meReducer, navActions, navReducer, roomActions, roomReducer } from './redux/reducers';

export interface IControllerState {
    connection: IConnectionState;
    room: IControllerRoomState;
    lobby: IControllerLobbyState;
    actionPanel: IControllerActionPanel;
    inbox: ROJ.IControllerInbox;
    directory: ROJ.IControllerDirectory;
    gameData: ROJ.IControllerGameData;
    gameState: ROJ.IControllerGameState;
    nav: IControllerNavState;
    me: ROJ.IPlayer;
};

export class ControllerConnector {
    pubsub: IPubSubProvider;
    reducer;
    store;
    prevState: IControllerState;
    private lastUnlockTime = 0;
    private lastMessageTime = 0;
    private lastJoinSeq = 0;

    constructor(
        public readonly sessionId: string,
        private readonly onStateChange: (state: IControllerState, prevState: IControllerState) => void,
        private readonly localTest: boolean,
    ) {
        this.reducer = combineReducers({
            room: roomReducer,
            lobby: lobbyReducer,
            gameData: gameDataReducer,
            gameState: gameStateReducer,
            connection: connectionReducer,
            actionPanel: actionPanelReducer,
            inbox: inboxReducer,
            directory: directoryReducer,
            me: meReducer,
            nav: navReducer,
        });

        this.store = configureStore({
            reducer: this.reducer,
            middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(
            ),
        });

        this.store.subscribe(() => {
            this.prevState = this.state;
            this.onStateChange(this.state, this.prevState);
        });
    }

    get state(): IControllerState {
        return this.store.getState();
    }

    async auth(sid: string, endpoint: string): Promise<GerdooAuthToken | undefined> {
        if (!sid) console.error('Cannot auth: No session id provided');
        const headers: any = {
            'Content-Type': 'application/json',
            'gerdoo-client-sid': sid,
            'gerdoo-client-role': 'player',
        };

        const res = await Axios.get(endpoint, { headers });
        if (res.status = 200) return res.data as GerdooAuthToken;

        return undefined;
    }

    async connect(authEndpoint: string): Promise<boolean> {
        const authToken = await this.auth(this.sessionId, authEndpoint);
        if (!authToken) return false;

        if (this.state.connection.state !== 'auth-success') {
            this.store.dispatch(connectionActions.authSuccess(authToken));
        }

        // this.pubsub = this.localTest ?
        //     new LocalWSClientrProvider(authToken.uuid, 'ws://localhost:3000') :
        //     new PubNubProvider({ ...pubsubKeys, uuid: authToken.uuid });
        this.pubsub = new PubNubProvider({ uuid: authToken.uuid, publishKey: authToken.publishKey, subscribeKey: authToken.subscribeKey });

        await waitUntil(() => this.state.connection.state === 'auth-success');
        this.store.dispatch(connectionActions.connecting());
        this.pubsub.subscribe(['frontdoor']);

        return this.initPubsub();
    }

    private initReconnectTimer() {
        setInterval(() => {
            if (this.state.connection.roomCode && this.lastMessageTime > 0) {
                const timeSinceLastMessage = Date.now() - this.lastMessageTime;
                if (timeSinceLastMessage > 10000 && this.state.connection.state !== 'disconnected') {
                    this.store.dispatch(connectionActions.disconnected());
                }
                else if (timeSinceLastMessage > 6000) {
                    this.store.dispatch(connectionActions.timeout());
                }

                // ping every second after 3s with no updates
                if (timeSinceLastMessage > 5000) {
                    this.sendCommand({
                        type: 'gerdoo.ping',
                    });
                }
            }
        }, 1000);
    }

    private async initPubsub(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.pubsub.init({
                onConnect: async ({ channels }) => {
                    console.log(`Connected to pubsub.`);

                    const channel = this.state.connection.authToken.channel;
                    if (channel && channels.indexOf(channel) < 0) {
                        await this.pubsub.subscribe([this.state.connection.authToken.channel]);
                        this.pubsub.publish('regplayer', {}); // Todo: pass player auth
                    }
                },
                onMessage: async (evt) => {
                    const { connection, gameData, gameState, inbox } = this.state;
                    const { authToken } = connection;

                    if (evt.channel === authToken.channel && evt.publisher !== authToken.uuid) {
                        if (evt.message.type === 'playerregconfirm') {
                            console.log(`Player registered: ${authToken.uuid}`);
                            this.store.dispatch(connectionActions.connected());
                            if (evt.message.roomCode) {
                                this.store.dispatch(connectionActions.joined({ roomCode: evt.message.roomCode }));
                            }

                            this.initReconnectTimer();
                            resolve(true);
                        }

                        this.lastMessageTime = Date.now();
                        const update = evt.message as IGerdooPlayerUpdate;
                        if (update && update.type && update.uuid === authToken.uuid) {
                            if (connection.state === 'timeout' || connection.state === 'disconnected') {
                                this.store.dispatch(connection.roomCode ? connectionActions.joined({ roomCode: connection.roomCode }) : connectionActions.connected());
                            }

                            if (update.type === 'roj.hostLock' && update.lock) {
                                const handle = setTimeout(() => {
                                    if (Date.now() - this.lastUnlockTime > 1000) {
                                        this.store.dispatch(actionPanelActions.lock());
                                    }
                                    clearTimeout(handle);
                                }, 1000);
                                return;
                            } else {
                                this.lastUnlockTime = Date.now();
                                this.store.dispatch(actionPanelActions.unlock());
                            }

                            if (update.type === 'gerdoo.error' && update.seq === this.lastJoinSeq) {
                                this.store.dispatch(navActions.toast({ msg: update.error }));
                                if (connection.state === 'joining') {
                                    this.store.dispatch(connectionActions.joinFailed());
                                }
                            }

                            switch (update.type) {
                                case 'room.refresh':
                                    this.store.dispatch(roomActions.update({ data: update.room }));
                                    break;
                                case 'lobby.refresh':
                                    this.store.dispatch(lobbyActions.update({ me: update.me, others: update.others }));
                                    break;
                                case 'lobby.join':
                                    this.store.dispatch(connectionActions.joined({ roomCode: update.roomCode }));
                                    break;
                                case 'lobby.leave':
                                    if (update.guestId === authToken.uuid) this.store.dispatch(connectionActions.left());
                                    break;
                                case 'lobby.kick':
                                    if (update.guestId === authToken.uuid) this.store.dispatch(connectionActions.left());
                                    break;
                                case 'roj.gameData':
                                    this.store.dispatch(gameDataActions.update(update.gameData));
                                    break;
                                case 'roj.gameState':
                                    this.store.dispatch(gameStateActions.update(update.gameState));
                                    break;
                                case 'roj.actionPanel':
                                    this.store.dispatch(actionPanelActions.update(update.actionPanel));
                                    break;
                                case 'roj.inbox':
                                    this.store.dispatch(inboxActions.update({ inbox: update.inbox, gameData, gameState }));
                                    break;
                                case 'roj.directory':
                                    this.store.dispatch(directoryActions.update(update.directory));
                                    break;
                                case 'roj.me':
                                    this.store.dispatch(meActions.update(update.me));
                                    break;
                            }
                        }
                    }
                },
                onPresence: (evt) => {
                    // console.log(evt);
                },
            });
        });
    }

    async join(name: string, code: string): Promise<boolean> {
        // if there is a room code, we should leave first
        const { connection } = this.state;
        if (connection.state !== 'connected' || connection.roomCode) return;

        this.lastJoinSeq = Date.now();
        this.pubsub.publish(connection.authToken.channel, {
            uuid: connection.authToken.uuid,
            seq: this.lastJoinSeq,
            type: 'lobby.join',
            service: `lobby`,
            roomCode: code,
            guest: {
                name,
                rank: 'player',
            }
        } as ILobbyJoinCommand);

        this.store.dispatch(connectionActions.joining());
        return waitUntil(() => this.state.connection.state === 'joined');
    }

    async leave(): Promise<boolean> {
        const { connection } = this.state;
        if (!connection.roomCode) return;

        this.pubsub.publish(connection.authToken.channel, {
            uuid: connection.authToken.uuid,
            type: 'lobby.leave',
            service: `lobby`,
            roomCode: connection.roomCode,
        } as ILobbyLeaveCommand);

        this.store.dispatch(connectionActions.left());
        return waitUntil(() => this.state.connection.state !== 'joined');
    }

    sendCommand(cmd: Partial<IGerdooPlayerCommand>) {
        const { connection } = this.state;

        if (connection.roomCode) {
            this.pubsub.publish(connection.authToken.channel, {
                uuid: connection.authToken.uuid,
                roomCode: connection.roomCode,
                ...cmd,
            });
        }
    }

    sendPlayerInput<ACTION extends keyof APInputDataType>(inputKey: string, action: ACTION, data: APInputDataType[ACTION]) {
        this.sendCommand({
            type: 'roj.input',
            service: 'roj',
            inputKey,
            action: action as any,
            data: data as any,
        });
    }
}
