import { Reducer, AnyAction } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GerdooAuthToken, IRoomConfig, RoomHostUpdateArgsMap, ROJ, RoomState, IRoom } from 'gerdoo-api';
import { createRoleList } from 'gerdoo-util';

export interface IControllerRoomState {
    state: RoomState;
    code: string;
}

const initialState: IControllerRoomState = {
    state: 'lobby',
    code: '',
};

const roomSlice = createSlice({
    name: 'room',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        update: (state, action: PayloadAction<{data: Partial<IRoom>}>) => {
            return {
                ...state,
                ...action.payload.data,
            }
        },
    },
    extraReducers: builder => {
    }
});

export const roomReducer: Reducer<IControllerRoomState, AnyAction> = roomSlice.reducer;
export const roomActions = {
    ...roomSlice.actions,
};
