import _ from "lodash";
import Immutable from "immutable";
import { ROJ } from "gerdoo-api";

import { IGameStateChunk } from ".";

export class ProposalState implements IGameStateChunk<ROJ.IProposal> {
    private _proposal: Immutable.Map<keyof ROJ.IProposal, string | number | boolean>;
    private _squad: Immutable.Set<string>;
    private _tempVotes: Immutable.Map<string, number>;
    private _votes: Immutable.Map<string, number>;
    private _dvs: Immutable.Set<string>;
    private _confirmed: Immutable.Set<string>;

    async key(): Promise<string> {
        return `proposal:${await this.getRoundNumber()}-${await this.getAttemptNumber()}`;
    }

    async value(): Promise<ROJ.IProposal> {
        const data = this._proposal.toJS() as any as ROJ.IProposal;
        const votes: {[pid:string]:number} = _.mapValues(this._votes.toJS(), (v: number, pid: string) => v);

        return ({
            ...data,
            votes,
            squad: this._squad.toArray(),
        });
    }

    async init(data: ROJ.IProposalInitialData) {
        this._proposal = Immutable.Map<keyof ROJ.IProposal, string | number>({
            round: data.round,
            attempt: data.attempt,
            leaderId: data.leaderId,
        });
        this._squad = Immutable.Set([data.leaderId]);
        this._tempVotes = Immutable.Map({});
        this._votes = Immutable.Map({});
        this._dvs = Immutable.Set([]);
        this._confirmed = Immutable.Set([]);
    }

    async load(proposal: ROJ.IProposal): Promise<ProposalState> {
        this._proposal = Immutable.Map<keyof ROJ.IProposal, string | number | boolean>({
            key: proposal.key,
            round: proposal.round,
            attempt: proposal.attempt,
            leaderId: proposal.leaderId,
            isSubmitted: proposal.isSubmitted,
            isFinished: proposal.isFinished,
            isApproved: proposal.isSubmitted,
        });

        return this;
    }

    async clear() {
        this._proposal.clear();
        this._squad.clear();
        this._tempVotes.clear();
        this._votes.clear();
        this._dvs.clear();
        this._confirmed.clear();
    }

    async getRoundNumber(): Promise<number> {
        return this._proposal.get('round') as number;
    }

    async getAttemptNumber(): Promise<number> {
        return this._proposal.get('attempt') as number;
    }

    async getLeaderId(): Promise<string> {
        return this._proposal.get('leaderId') as string;
    }

    async pick(pid: string) {
        this._squad = this._squad.add(pid);
    }

    async unpick(pid: string) {
        this._squad = this._squad.remove(pid);
    }

    async squadContains(pid: string): Promise<boolean> {
        return this._squad.contains(pid);
    }

    async getSquad(): Promise<string[]> {
        return this._squad.toJS() as string[];
    }

    async tempVote(pid: string, vote: number) {
        if (!await this.hasConfirmed(pid)) {
            this._tempVotes = this._tempVotes.set(pid, vote);
        }
    }
    
    async setVote(pid: string) {
        if (!await this.hasConfirmed(pid)) {
            var vote = this._tempVotes.get(pid);
            if (this._dvs.contains(pid)) vote *= 2;
            this._votes = this._votes.set(pid, vote);
        }
    }

    async setDV(pid: string, value: boolean) {
        if (!await this.hasConfirmed(pid)) {
            this._dvs = value ? this._dvs.add(pid) : this._dvs.remove(pid);
        }
    }

    async getTempVote(pid: string): Promise<number> {
        return (this._tempVotes.toJS()[pid] as number) || 0;
    }

    async getVote(pid: string): Promise<number> {
        return (this._votes.toJS()[pid] as number) || 0;
    }

    async getDV(pid: string): Promise<boolean> {
        return this._dvs.contains(pid);
    }

    async confirm(pid: string) {
        this._confirmed = this._confirmed.add(pid);
    }

    async hasConfirmed(pid: string): Promise<boolean> {
        return this._confirmed.contains(pid);
    }

    async submit(): Promise<void> {
        this._proposal = this._proposal
            .set('isSubmitted', true);
    }

    async setResult(isApproved: boolean): Promise<void> {
        this._proposal = this._proposal
            .set('isApproved', isApproved)
            .set('isFinished', true);
    }

    async isApproved(): Promise<boolean> {
        return this._proposal.get('isApproved') as boolean;
    }
}
