import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROJ } from 'gerdoo-api';

export interface IControllerNavState {
    toast: {msg: string};
}

const navSlice = createSlice({
  name: 'nav',
  initialState: {
    toast: {msg: ''},
  },
  reducers: {
    toast: (state, action: PayloadAction<{msg: string}>) => {
        return {
            ...state,
            toast: {msg: action.payload.msg},
        }
    },
  },
});

export const navReducer = navSlice.reducer;
export const navActions = { ...navSlice.actions };
