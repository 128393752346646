import Immutable from "immutable";
import { ROJ } from "gerdoo-api";

import { IGameStateChunk } from ".";

export class PlayerState implements IGameStateChunk<ROJ.IPlayer> {
    private _player: Immutable.Map<keyof ROJ.IPlayer, any>;
    private _tags: Immutable.List<ROJ.IPlayerTag>;
    private _sus: Immutable.List<ROJ.IPlayerTag>;

    async key(): Promise<string> {
        return `player:${await this.getUUID()}`;
    }

    async init(data: ROJ.IPlayerInitialData) {
        this._tags = Immutable.List<ROJ.IPlayerTag>([]);
        this._sus = Immutable.List<ROJ.IPlayerTag>([]);
        this._player = Immutable.Map<keyof ROJ.IPlayer, any>({
            pid: data.pid,
            uuid: data.uuid,
            name: data.name,
            character: data.character,
        });
    }

    async clear(): Promise<void> {
        this._player.clear();
    }

    async value(): Promise<ROJ.IPlayer> {
        return this._player.toJS() as any as ROJ.IPlayer;
    }

    async load(player: ROJ.IPlayer): Promise<PlayerState> {
        this._player = Immutable.Map<keyof ROJ.IPlayer, any>(player);
        return this;
    }

    async getId(): Promise<string> {
        return this._player.get('pid');
    }

    async getUUID(): Promise<string> {
        return this._player.get('uuid');
    }

    async setRole(role: ROJ.Role): Promise<void> {
        this._player = this._player.set('role', role);
    }

    async getRole(): Promise<ROJ.Role> {
        return this._player.get('role');
    }

    async setTeam(team: ROJ.Team): Promise<void> {
        this._player = this._player.set('team', team);
    }

    async getTeam(): Promise<ROJ.Team> {
        return this._player.get('team');
    }

    async setCharacter(character: string): Promise<void> {
        this._player = this._player.set('character', character);
    }

    async confirmCharacter(): Promise<void> {
        this._player = this._player.set('characterConfirmed', true);
    }

    async getCharacter(): Promise<string> {
        return this._player.get('character');
    }

    async addTag(tag: ROJ.IPlayerTag): Promise<void> {
        const existingIndex = this._tags.findIndex(t => t.label === tag.label && t.taggedPID === tag.taggedPID);
        if (existingIndex < 0) {
            this._tags = this._tags.push(tag);
        }
    }

    async removeTag(tag: string, pid: string): Promise<void> {
        const i = this._tags.findIndex(t => t.label === tag && t.taggedPID === pid);
        if (i >= 0)
            this._tags = this._tags.remove(i);
    }

    async getTags(): Promise<ROJ.IPlayerTag[]> {
        return this._tags?.toArray() || [];
    }
    
    async setSus(tag: ROJ.IPlayerTag): Promise<void> {
        const existingIndex = this._sus.findIndex(t => t.taggedPID === tag.taggedPID);
        if (existingIndex >= 0) {
            this._sus = this._sus.remove(existingIndex);
        }
        
        if (tag.label) {
            this._sus = this._sus.push(tag);
        }
    }

    async getSus(): Promise<ROJ.IPlayerTag[]> {
        return this._sus?.toArray() || [];
    }
}
