import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROJ } from 'gerdoo-api';

const directorySlice = createSlice({
  name: 'directory',
  initialState: {
    tags: [],
    sus: [],
  } as ROJ.IControllerDirectory,
  reducers: {
    update: (state, action: PayloadAction<ROJ.IControllerDirectory>) => {
      return {
        ...state,
        ...action.payload,
      };
    }
  },
});

export const directoryReducer = directorySlice.reducer;
export const directoryActions = { ...directorySlice.actions };
