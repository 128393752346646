import _ from "lodash";
import React, { useContext } from "react";
import { IonAvatar, IonCard, IonChip, IonCol, IonLabel, IonRow } from "@ionic/react";

import { APInputArgsMap, APInputDataType, APInputOf, APPickerInputDataType, ROJ } from 'gerdoo-api';
import { AppContext } from "../../apps/ControllerApp";
import { IRojAvatarImgProps, IRojInteractable, RojAvatar, RojImage } from "../RojAvatar";

export interface IPickerInputProps {
    game: ROJ.IControllerGameData;
    input: APInputOf<'picker'>;
    onAction: <ACTION extends keyof APPickerInputDataType>(action: ACTION, data: APInputDataType[ACTION]) => void;
}

export const PickerInput: React.FC<IPickerInputProps> = ({ game, input, onAction }) => {
    const context = useContext(AppContext);
    const { cdn } = context;
    const { args, value } = input;

    const toggle = (id: string) => onAction(value.indexOf(id) >= 0 ? 'picker.unpick' : 'picker.pick', { id });
    const list = getList(cdn, game, args, value.filter(v => !!v), toggle);

    return <>
        <IonCol>
            <IonRow className='ion-text-center ion-justify-content-center ion-padding-bottom'>
                {list}
            </IonRow>
            { args.count > 1 && <IonRow className="ion-text-center ion-justify-content-center">
                <IonLabel color={'secondary'}>{`${value.length}/${args.count}`}</IonLabel>
            </IonRow> }
        </IonCol>
    </>;
};

function getList(cdn: string, game: ROJ.IControllerGameData, args: APInputArgsMap['picker'], selected: string[], toggle: (id: string) => void) {
    const moreOptions = (args.moreOptions || []).map(option => {
        // switch (args.template) {
        //     case 'card': return <IonCard key={option.id} onClick={() => toggle(option.id)}>{option.label}</IonCard>;
        //     case 'avatar': default: return <IonAvatar key={option.id} onClick={() => toggle(option.id)}>{option.label}</IonAvatar>;
        //     case 'chip': return <IonChip key={option.id} onClick={() => toggle(option.id)}>{option.label}</IonChip>;
        // }
        return <IonChip 
            key={option.id}
            className="ion-margin-vertical ion-padding-vertical ion-justify-content-center"
            style={{width: 80, height: 80, borderRadius: 40}}
            color={selected.indexOf(option.id)>=0 ? 'success' : ''}
            onClick={() => toggle(option.id)}
        >{option.label}</IonChip>
    });

    switch (args.collectionId) {
        case 'players':
            const pickablePlayers = _.values(game.players).filter(p => args.options === 'all' || args.options.indexOf(p.id) >= 0);

            return <> {
                pickablePlayers.map(p => {
                    const picked = selected.indexOf(p.id) >= 0;
                    const props: IRojInteractable = {
                        onClick: () => toggle(p.id),
                        selected: picked,
                        disabled: (args.disabled || []).indexOf(p.id) >= 0 || (!picked && selected.length === args.count && args.count > 1),
                    };

                    return <div key={p.id} style={{margin: 3}}><RojAvatar key={p.id} {...props} caption={p.name} img={<RojImage type='player' id={p.id} />} /></div>;
                })
            }
                {moreOptions}
            </>;

        case 'characters':
            return <> {
                _.values(game.characters).map(ch => {
                    const picked = selected.indexOf(ch.id) >= 0;
                    const props: IRojInteractable = {
                        onClick: () => toggle(ch.id),
                        selected: picked,
                        disabled: (args.disabled || []).indexOf(ch.id) >= 0,
                    };

                    return <div key={ch.id} style={{margin: 3}}><RojAvatar key={ch.id} {...props} img={<RojImage type='character' id={ch.id} />} /></div>;
                })
            }
            {moreOptions}
            </>;
    }
}
