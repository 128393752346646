import _ from "lodash";
import { useEffect, useReducer } from "react";

import { IPubSubKeys } from 'gerdoo-api';
import { ControllerConnector } from "../ControllerConnector";

let controllers: {[uuid:string]: ControllerConnector} = {};

export function useControllerConnector(sessionId: string, auth: string, localTest: boolean): ControllerConnector | undefined {
    const [,forceUpdate] = useReducer(a=>a+1, 0);

    useEffect(() => {
        controllers[sessionId] = new ControllerConnector(
            sessionId,
            (state) => forceUpdate(),
            localTest,
        );
        controllers[sessionId].connect(auth);
    }, []);

    return controllers[sessionId];
}
