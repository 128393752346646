import _ from 'lodash';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { IControllerState } from 'gerdoo-controller-connector';
import { AppContext } from '../apps/ControllerApp';

export const ControllerLobby: React.FC = () => {
    const { connector } = useContext(AppContext);
    const { connection, lobby } = useSelector((state: IControllerState) => state);

    return <div className="joinForm container-login100">
    <div className="wrap-login100">
        <div className="login100-form">
                { lobby?.me?.name && <h5 className="p-b-48">
                    Welcome, {lobby?.me?.name}
                </h5> }
                <br/><br/>
                <h2 className="login100-form-title p-b-48">
                    {connection.roomCode}
                </h2>
                <br/><br/><br/>
            <h3><i>Waiting for the host to start the game...</i></h3>
            <br/>
            <div className="container-login100-form-btn">
                <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button className="login100-form-btn" onClick={() => connector.leave()}>
                        Leave
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>;
}
