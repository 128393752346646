import Immutable from "immutable";

import { IGameStateChunk } from ".";

export class ListState<T> implements IGameStateChunk<T[]> {
    private _list: Immutable.List<T>;

    constructor(private readonly _key: string) {}

    async init(initList: T[]): Promise<void> {
        this._list = Immutable.List(initList || []);
    }

    async load(list: T[]): Promise<ListState<T>> {
        this._list = Immutable.List(list || []);
        return this;
    }

    async clear(): Promise<void> {
        this._list = this._list.clear();
    }

    async key(): Promise<string> {
        return this._key;
    }

    async value(): Promise<T[]> {
        return this._list.toJS() as any as T[];
    }

    async add(item: T): Promise<void> {
        this._list = this._list.push(item);
    }

    async remove(item: T): Promise<void> {
        this._list = this._list.remove(this._list.indexOf(item));
    }

    async removeAt(index: number): Promise<void> {
        this._list = this._list.remove(index);
    }

    async get(index: number): Promise<T> {
        return this._list.get(index);
    }

    async getAll(): Promise<T[]> {
        return [...(this._list?.values() || [])];
    }

    async size(): Promise<number> {
        return this._list.size;
    }

    async contains(item: T) {
        return this._list.contains(item);
    }

    async rotate(): Promise<void> {
        const first = this._list.get(0);
        this._list = (this._list.shift()).push(first);
    }
}
